div > span {
    opacity: 0.75;
}



.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-btn {
    cursor: pointer;
    height: 50px;
    width: 50px;
    font-family: 'Titillium Web', sans-serif;
    color: #333;
    border-radius: 10px;
    box-shadow: 0px 10px 10px rgba(0,0,0,0.1);
    background: white;
    margin: 10px;
    transition: 1s;
}

.social-btn span {
    width: 0px;
    overflow: hidden;
    transition: 1s;
    text-align: center;
}

.social-btn:hover {
    width: 150px;
    border-radius: 5px;
}

.social-btn:hover span {
    padding: 2px;
    width: max-content;
}

#twitter svg {
    fill: #1da1f2;
}

#linkedin svg {
    fill: #0e76a8;
}

#github {
    fill: #333;
}


.landing-name{
    position: relative;
    margin-top: 100px;
    margin-bottom: 50px;
}


.landing-name > span {
    font-size: 1.5em !important;
}

@media screen and (min-width: 1300px) {
    .landing-name{
        margin-top: 150px;
        margin-bottom: 0;
    }
}

@media screen and (max-width: 1300px) {
    .landing-name > span {
        font-size: 1em !important;
    }
}

@media screen and (max-width: 480px) {
    .landing-name {
        display: none;
    }

    .landing-name > span {
        font-size: 0.5em !important;
        text-align: center !important;
    }
}


img {
    width: 100%;
    height: auto;
    background: #f0f0f0;
}

ul {
    padding-left: 1rem;
    list-style: none;
}

li {
    flex-shrink: 0;
    width: clamp(500px, 60vw, 600px);
    padding-right: 1rem;
}

header {height: 100vh}
footer {height: 50vh}

:any-link { color: #4e9815; }

.df {display: flex}
.aic {align-items: center}
.jcc {justify-content: center}

.loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: black;
    color: white;
}

.demo-wrapper {
    overflow-x: hidden;
}

.wrapper {
    display: flex;
    flex-direction: row !important;
}

.demo-gallery:not(.last) {
    padding-bottom: 0.5rem;
}

.demo-text .text {
    font-size: clamp(8rem, 15vw, 16rem);
    line-height: 1;
    font-weight: 900;
}

.blockquote-wrapper {
    display: flex;
    padding: 0 20px;
}

/* Blockquote main style */
.blockquote {
    position: relative;
    font-family: 'Roboto', sans-serif;
    max-width: 620px;
    margin: 80px auto;
    align-self: center;
}

/* Blockquote header */
.blockquote h1 {
    font-family: 'Roboto', sans-serif;
    position: relative; /* for pseudos */
    color: #4c6770;
    font-size: 2.8rem;
    font-weight: normal;
    line-height: 1;
    margin: 0;
    border: 3px solid #fff;
    border: solid 3px;
    letter-spacing: 0.05em;
    border-radius:20px;
    padding: 25px;
}

/* Blockquote right double quotes */
.blockquote h1:after {
    content:"";
    position: absolute;
    border: 3px solid #4c6770;
    border-radius: 0 50px 0 0;
    width: 60px;
    height: 60px;
    bottom: -60px;
    left: 50px;
    border-bottom: none;
    border-left: none;
    z-index: 3;
}

.blockquote h1:before {
    content:"";
    position: absolute;
    width: 80px;
    border: 6px solid #ddd;
    bottom: -3px;
    left: 50px;
    z-index: 2;
}

/* increase header size after 600px */
@media all and (min-width: 600px) {
    .blockquote h1 {
        font-size: 3rem;
        line-height: 1.2;
    }

}

/* Blockquote subheader */
.blockquote h4 {
    position: relative;
    color: #ffffff;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.2;
    margin: 0;
    padding-top: 15px;
    z-index: 1;
    margin-left:150px;
    padding-left:12px;
}


.blockquote h4:first-letter {
    margin-left:-12px;
}
